.postCard {
    display: flex;
    flex-direction: column;
    block-size: 100%;

    padding: var(--space-24) var(--space-16) var(--space-24) var(--space-32);
    border: 1px solid var(--color-black-04);
    border-radius: var(--radius-md);
    transition: box-shadow var(--animation-duration-150) var(--animation-ease-base);
}
.postCard:hover {
    text-decoration: none;
    box-shadow: var(--shadow-sm);
}
.postCard:focus {
    box-shadow: var(--shadow-focus);
}

.date {
    color: var(--color-grey-07);
}

.title {
    padding-block-start: var(--space-8);
    padding-block-end: var(--space-4);
}

.snippet {
    color: var(--color-grey-07);
}
