.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    text-align: center;
    max-inline-size: 752px;
}

.illustration {
    margin-block-start: var(--space-32);
}
