.postCardGrid {
    margin-block-start: var(--space-48);
    display: flex;
    flex-direction: column;
}

.searchBar {
    inline-size: 343px;
    max-inline-size: 100%;
    margin: 0 auto;
}

.grid {
    padding: var(--space-24) 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, 248px);
    gap: var(--space-32);
    margin: 0 auto;
    max-inline-size: 810px;
    inline-size: 100%;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .grid {
        grid-template-columns: repeat(auto-fit, minmax(248px, 1fr));
    }
}
